<template>
  <div
    class="pt-8 pb-8 d-flex justify-center"
    :class="{
      'pr-8 pl-8 mr-4 ml-4': $vuetify.breakpoint.smAndDown,
      'pr-16 pl-16 mr-8 ml-8': $vuetify.breakpoint.mdAndUp,
    }"
  >
    <v-app-bar app class="templa-email-app-bar">
      <div class="text-h6 font-weight-bold ml-3">Campanhas URA</div>
      <v-spacer></v-spacer>
      <!--<v-switch
        hide-details=""
        label="Campanhas arquivadas"
        class="mr-4"
        v-model="mostrarArquivadas"
        :disabled="carregando"
      ></v-switch> -->
    </v-app-bar>
    <v-container class="d-flex justify-center flex-column">
      <v-skeleton-loader
        v-if="carregando"
        class="mx-auto"
        width="1000"
        type="table-tbody"
      ></v-skeleton-loader>
      <v-data-table
        v-else-if="!carregando"
        :headers="cabecalhos"
        :items="campanhas"
        :items-per-page="-1"
        class="elevation-1"
        hide-default-footer
        :key="campanhasKey"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr class="text-center" v-show="campanhas.length === 0 && !carregando">
              <td colspan="12">Nenhuma iniciativa encontrada</td>
            </tr>
            <tr
              v-for="item in items"
              :key="item?.id"
              @mouseover="ativarMenu(item)"
              @mouseleave="desativarMenu()"
              class="pt-2 pb-2"
            >
              <td class="text-start">
                <span>
                  {{ item.campanha }}
                </span>
              </td>

              <td class="text-start mensagem">
                <span class="two-lines">
                  {{ item.roteiro }}
                </span>
              </td>

              <td class="text-start">
                <span>
                  {{ item.tipo_regua | tipoRegua }}
                </span>
              </td>

              <td class="text-center menuAcoes px-0">
                <v-menu
                  v-if="item?.id === itemSelecionado"
                  left
                  offset-x
                  v-model="menuAberto"
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="action-button" icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="handleEditar(item.id)">
                      <v-list-item-title>
                        <v-btn plain> Editar </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-container>
    <FloatingBtn @click="handleCriar()" />
    <DialogCampanha ref="dialogCampanha" @cancelar="handleCancelar" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DialogCampanha from "../components/DialogCampanha.vue";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";

export default {
  components: { DialogCampanha, FloatingBtn },
  data() {
    return {
      cabecalhos: [
        {
          text: "Campanha",
          value: "campanha",
          align: "left",
          sortable: true,
          width: "35%",
        },
        {
          text: "Roteiro",
          value: "roteiro",
          align: "left",
          sortable: false,
          width: "40%",
        },
        {
          text: "Régua",
          value: "tipo_regua",
          align: "left",
          sortable: true,
          width: "15%",
        },
        {
          text: "",
          value: "menu",
          align: "right",
          sortable: false,
          width: "10%",
        },
      ],
      campanhasKey: null,
      mostrarArquivadas: false,
      carregandoCampanhas: false,
      menuAberto: false,
      itemSelecionado: false,
    };
  },
  computed: {
    ...mapGetters("campanhas", ["todasCampanhas", "campanhas", "carregando"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),
  },
  watch: {
    empresaSelecionada: {
      handler() {
        this.carregarDados();
      },
      deep: true,
    },
    mostrarArquivadas: {
      handler() {
        this.carregarDados();
      }
    },
  },
  async created() {
    await this.carregarDados();
  },
  methods: {
    ...mapActions("campanhas", {
      carregarItens: "getCampanhas",
    }),
    ativarMenu(item) {
      if (!this.menuAberto) this.itemSelecionado = item.id;
      return;
    },
    desativarMenu() {
      if (!this.menuAberto) this.itemSelecionado = false;
      return;
    },
    existeItemSelecionado() {
      return this.itemSelecionado;
    },
    handleEditar(id) {
      const campanha = this.todasCampanhas.find((item) => item.id == id);
      if (campanha) {
        this.$refs.dialogCampanha.campanha = campanha;
        this.$refs.dialogCampanha.abrirDialog();
      }
    },
    handleCriar() {
      this.$refs.dialogCampanha.campanha = null;
      this.$refs.dialogCampanha.limpaCampos();
      this.$refs.dialogCampanha.abrirDialog();
    },
    handleCancelar() {
      this.$refs.dialogCampanha.fecharDialog();
    },
    async carregarDados() {
      await this.carregarItens({
        empresa_id: this.empresaSelecionada.id,
        limite: 10000,
        ordem: "status",
        deletado: this.mostrarArquivadas,
      });
    },
  },
};
</script>
<style lang="scss">
.mensagem {
  max-width: 150px;
  width: 200px;
  min-width: 150px;
}
.two-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>

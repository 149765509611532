<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card>
      <v-card-title class="text-h5 justify-center">Pausar notificação até</v-card-title>
      <v-card-text>
        <v-menu offset-y transition="scale-transition" class="mx-auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dataFormatada"
              append-icon="mdi-menu-down"
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
              class="input-data mx-auto"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dataSelecionada"
            no-title
            :allowed-dates="verificaData"
            locale="pt-br"
          >
          </v-date-picker>
        </v-menu>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="fecharDialog()"> Fechar </v-btn>
        <v-btn color="primary" @click="salvar()" :loading="carregando"> salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    dialog: false,
    dataSelecionada: null,
    parcelaSelecionada: null,
    carregando: false,
  }),
  methods: {
    ...mapActions("parcela", {
      pausarParcela: "pausarParcela",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    verificaData(data) {
      let hoje = moment().format("YYYY-MM-DD");
      if (data >= hoje) return true;
      return false;
    },
    abrirDialog(parcela) {
      this.parcelaSelecionada = parcela;
      if (parcela.nao_notificar_ate) this.dataSelecionada = parcela.nao_notificar_ate;
      this.dialog = true;
    },
    salvar() {
      this.carregando = true;
      let payload = {
        parcela_id: this.parcelaSelecionada.id,
        nao_notificar_ate: this.dataSelecionada,
      };
      this.pausarParcela(payload)
        .then((retorno) => {
          this.carregando = false;
          this.dialog = false;
          this.$emit("parcelaPausada", retorno);
          this.showSnackBar({
            text: "Parcela pausada com sucesso.",
          });
          this.limparCampos();
        })
        .catch((error) => {
          console.error(error);
          this.carregando = false;
          this.showSnackBar({
            text: "Erro ao pausar a parcela.",
          });
        });
    },
    fecharDialog() {
      this.dialog = false;
      this.limparCampos();
    },
    limparCampos() {
      this.dataSelecionada = null;
      this.parcelaSelecionada = null;
    },
  },
  computed: {
    ...mapGetters("parcela", {
      erroPausarParcela: "erroPausarParcela",
    }),
    dataFormatada() {
      if (!this.dataSelecionada) return;
      let dataFormatadaTeste = moment(this.dataSelecionada).format("L");
      return dataFormatadaTeste;
    },
  },
};
</script>

<style scoped lang="scss">
.input-data {
  width: 150px !important;
}
</style>

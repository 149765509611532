<template>
  <div>
    <v-form
      @submit="pesquisar"
      class="ma-auto mt-16 pa-4 formulario text-center"
      :class="{ 'pb-0': resultadoPesquisa }"
    >
      <h1 class="mb-5 text-h5 text-center text--secondary">
        Pesquise por CPF, telefone, e-mail ou código do contrato
      </h1>

      <v-text-field
        v-model="filtros.consulta"
        hide-details
        prepend-inner-icon="mdi-magnify"
        solo
        autofocus
        required
        filled
      >
        <template v-slot:append-outer>
          <v-btn fab color="primary" small type="submit">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
      </v-text-field>

      <div class="mt-4" v-if="!pesquisando && (contratos?.length || consultaClientes?.length)">
        <span class="grey--text"> Resultados da pesquisa: {{ resultadoPesquisa }} </span>
        <v-btn icon @click="limparPesquisa()">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
      </div>
    </v-form>

    <div v-if="pesquisando" class="ml-carregando">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-alert
      v-if="!pesquisando && erroPesquisa"
      type="error"
      max-width="400"
      class="mx-auto text-center"
      text
    >
      Não foi possível pesquisar. Tente novamente.
    </v-alert>

    <div
      v-if="!pesquisando && !erroPesquisa && !contratos && !consultaClientes"
      class="mt-4 pa-4 text-center text-h6 text--secondary font-weight-regular"
    >
      Nenhum contrato ou cliente encontrado
    </div>

    <div v-for="resultadoEmpresa in resultadosPorEmpresa">
      <section v-if="!pesquisando && empresaTemDados(resultadoEmpresa.empresa)">
        <div class="mt-16 px-4 text-center text--secondary">
          {{ resultadoEmpresa.empresaNome }}
        </div>

        <div v-for="(contrato, index) in resultadoEmpresa.consultaContrato">
          <v-card v-if="contrato" max-width="1000" class="ma-auto mt-4">
            <v-list class="pa-0">
              <v-list-item
                @click="
                  handleVerContrato({
                    name: 'VerContrato',
                    params: { id: contrato.id },
                    query: { empresa: resultadoEmpresa.empresa },
                  })
                "
                :key="'contrato-' + contrato.id"
              >
                <v-list-item-content>
                  <v-row class="pt-2">
                    <v-col cols="12" sm="3">
                      <div class="font-weight-bold mb-1">
                        {{ contrato.cliente.nome }}
                      </div>
                      <div class="text--secondary">
                        {{ contrato.cliente.documento | documentoCliente }}
                      </div>
                    </v-col>

                    <v-col cols="12" sm="7">
                      <div class="font-weight-medium mb-1">
                        {{ contrato.composicao }}
                      </div>
                      <div>
                        {{ contrato.ref_externa }}
                      </div>
                    </v-col>

                    <v-col cols="12" sm="2" class="text-right">
                      <div
                        class="font-weight-medium mb-1 status-contrato-fonte"
                        :class="contrato.status"
                      >
                        {{ contrato.status | statusContrato }}
                      </div>
                      <div class="text-body-2">
                        <span v-if="contrato.qtde_parcelas > 0">
                          {{ contrato.qtde_parcelas }}
                          {{ contrato.qtde_parcelas > 1 ? "parcelas" : "parcela" }}
                        </span>
                        <span v-else>Sem parcelas</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </v-list>
          </v-card>
        </div>

        <div v-for="(cliente, index) in resultadoEmpresa.consultaCliente">
          <v-card v-if="cliente" max-width="1000" class="ma-auto mt-4">
            <v-list class="pa-0">
              <v-list-item
                @click="
                  handleVerCliente({
                    name: 'VerCliente',
                    params: { id: cliente.id },
                    query: { empresa: resultadoEmpresa.empresa },
                  })
                "
                :key="'cliente-' + cliente.id"
              >
                <v-list-item-content>
                  <v-row class="pt-2">
                    <v-col cols="12" sm="3">
                      <div class="font-weight-bold mb-1">
                        {{ cliente.nome }}
                      </div>
                      <div class="text--secondary">
                        {{ cliente.documento | documentoCliente }}
                      </div>
                    </v-col>
                    <v-col class="text-right">
                      <div
                        class="font-weight-medium mb-1 subtitle-1"
                        style="color: #716868 !important"
                      >
                        {{ cliente.contratos?.length }}
                        {{ cliente.contratos?.length == 1 ? "contrato" : "contratos" }}
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

              <v-divider :key="index"></v-divider>
            </v-list>
          </v-card>
        </div>
      </section>
    </div>

    <div
      v-if="logsPorEmpresa.length"
      class="mt-16 px-4 text-center flex align-center justify-center gap-2"
      style="font-size: 1.25rem"
    >
      <v-icon>mdi-history</v-icon>
      <span class="font-weight-regular text--secondary"> Últimos acessos</span>
    </div>

    <div v-if="logsPorEmpresa.length" v-for="resultadoLogEmpresa in logsPorEmpresa">
      <section v-if="empresaTemLogs(resultadoLogEmpresa.empresa)">
        <div class="mt-8 px-4 text-center text--secondary">
          {{ resultadoLogEmpresa.empresaNome }}
        </div>
        <div v-for="log in resultadoLogEmpresa.logs">
          <v-card max-width="1000" class="ma-auto my-5">
            <v-list class="pa-0">
              <template>
                <v-list-item
                  @click="
                    handleVerContrato({
                      name: 'VerContrato',
                      params: { id: log.id },
                      query: { empresa: resultadoLogEmpresa.empresa },
                    })
                  "
                  :key="'contrato-' + log.id"
                  v-if="log.tipo == 'contrato'"
                >
                  <v-list-item-content>
                    <v-row class="pt-2">
                      <v-col cols="12" sm="3">
                        <div class="font-weight-bold mb-1">
                          {{ log.cliente.nome }}
                        </div>
                        <div class="text--secondary">
                          {{ log.cliente.documento | documentoCliente }}
                        </div>
                      </v-col>

                      <v-col cols="12" sm="7">
                        <div class="font-weight-medium mb-1">
                          {{ log.composicao }}
                        </div>
                        <div>
                          {{ log.ref_externa }}
                        </div>
                      </v-col>

                      <v-col cols="12" sm="2" class="text-right">
                        <div
                          class="font-weight-medium mb-1 status-contrato-fonte"
                          :class="log.status"
                        >
                          {{ log.status | statusContrato }}
                        </div>
                        <div class="text-body-2">
                          <span v-if="log.parcelas > 0"
                            >{{ log.parcelas }}
                            {{ log.parcelas > 1 ? "parcelas" : "parcela" }}</span
                          >
                          <span v-else>Sem parcelas</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    handleVerCliente({
                      name: 'VerCliente',
                      params: { id: log.id },
                      query: { empresa: resultadoLogEmpresa.empresa },
                    })
                  "
                  :key="log.tipo + log.id"
                  v-if="log.tipo == 'cliente'"
                >
                  <v-list-item-content>
                    <v-row class="pt-2">
                      <v-col cols="12" sm="3">
                        <div class="font-weight-bold mb-1">
                          {{ log.nome }}
                        </div>
                        <div class="text--secondary">
                          {{ log.documento | documentoCliente }}
                        </div>
                      </v-col>

                      <v-spacer></v-spacer>

                      <v-col cols="12" sm="2" class="text-right">
                        <div
                          class="font-weight-medium mb-1 subtitle-1"
                          style="color: #716868 !important"
                        >
                          {{ numeroContratos(log.contratos) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-list>
          </v-card>
        </div>
      </section>
    </div>

    <FloatingMultipleBtn v-if="empresaSelecionada.fake">
      <template v-slot:content>
        <v-chip
          class="text-subtitle-2 white--text orange darken-2"
          @click="abrirFormulario('FINANCIAMENTO')"
        >
          Financiamento</v-chip
        >
        <v-chip
          class="text-subtitle-2 white--text blue darken-2 mx-2"
          @click="abrirFormulario('SEGURO')"
        >
          Seguro</v-chip
        >
        <v-chip
          class="text-subtitle-2 ml-2 red darken-2 white--text"
          @click="abrirFormulario('CONSORCIO')"
        >
          Consórcio
        </v-chip>
        <v-chip
          class="text-subtitle-2 ml-2 white--text green darken-2"
          @click="abrirFormulario('COBRANCA')"
        >
          Cobrança
        </v-chip>
        <v-chip class="text-subtitle-2 white--text grey" @click="abrirFormulario('GENERICO')">
          Genérico
        </v-chip>
      </template>
    </FloatingMultipleBtn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FloatingMultipleBtn from "../../components/comum/FloatingMultipleBtn.vue";

export default {
  components: {
    FloatingMultipleBtn,
  },
  data() {
    return {
      erroPesquisa: false,
      pesquisando: false,
      filtros: {
        consulta: null,
      },
      ultimosContratosAcessados: [],
      floatingBtn: false,
    };
  },
  async created() {},
  mounted() {
    this.filtros.consulta = this.$route.query.consulta;
    this.pesquisarLogsAcesso();

    if (this.filtros.consulta) {
      this.pesquisar();
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.pesquisar();
    return next();
  },
  methods: {
    ...mapActions("contratos", {
      getContratosMultiempresa: "getContratosMultiempresa",
      limparContratos: "limparContratos",
      getLogContratos: "getLogContratos",
    }),
    ...mapActions("cliente", {
      getClientesMultiempresa: "getClientesMultiempresa",
      limparClientes: "limparClientes",
    }),
    ...mapActions("geral", {
      alteraResultadoPesquisa: "alteraResultadoPesquisa",
    }),
    ...mapActions("empresa", {
      setEmpresaSelecionada: "setEmpresaSelecionada",
    }),

    abrirFormulario(tipo) {
      this.$router.push({ name: "ContratoFake", query: { tipo: tipo } }).catch(() => {});
    },
    limparPesquisa() {
      this.limparContratos();
      this.limparClientes();
      this.filtros.consulta = null;
      this.alteraResultadoPesquisa(null);
    },

    async handleVerContrato({ name, params, query }) {
      this.$router.push({ name, params, query });
    },

    async handleVerCliente({ name, params, query }) {
      this.$router.push({ name, params, query });
    },

    async pesquisar(e) {
      if (e) e.preventDefault();
      if (this.pesquisando) return false;
      if (!this.filtros.consulta) return false;

      const params = {
        consulta: this.filtros.consulta,
        limite: 100,
      };

      this.$router
        .push({
          query: {
            consulta: this.filtros.consulta,
          },
        })
        .catch(() => {});

      this.pesquisando = true;
      this.erroPesquisa = false;

      try {
        await this.getClientesMultiempresa(params);
        await this.getContratosMultiempresa(params);
        // this.ordenarContratos();
        this.alteraResultadoPesquisa(this.filtros.consulta);
      } catch (erro) {
        console.error("Não foi possível pesquisar os contratos.", erro);
        this.erroPesquisa = true;
      } finally {
        this.pesquisando = false;
      }
    },

    ordenarContratos() {
      if (!this.contratos) return;
      this.contratos.forEach((contrato) => {
        if (contrato.status == "ATIVO") {
          contrato["ordem"] = 1;
        } else if (contrato.status == "FINALIZADO") {
          contrato["ordem"] = 2;
        } else {
          contrato["ordem"] = 3;
        }
      });
      this.contratos.sort((a, b) => (a.ordem > b.ordem ? 1 : -1));
    },

    /** Pesquisa pelos últimos contratos acessados. */
    async pesquisarLogsAcesso() {
      const parametros = { limite: 10 };

      const result = await this.getLogContratos(parametros);
      if (result) {
        this.ultimosContratosAcessados = result;
      }
    },

    numeroContratos(contratos) {
      if (contratos == 0) {
        return "Nenhum contrato";
      } else if (contratos == 1) {
        return contratos + " contrato";
      } else {
        return contratos + " contratos";
      }
    },

    empresaTemDados(empresaChave) {
      const empresa = this.resultadosPorEmpresa.find((r) => r.empresa === empresaChave);
      if (!empresa) return false;

      return empresa.consultaCliente.length || empresa.consultaContrato.length;
    },
    empresaTemLogs(empresaChave) {
      const empresa = this.logsPorEmpresa.find((r) => r.empresa === empresaChave);
      if (!empresa) return false;

      return empresa.logs.length;
    },
  },
  computed: {
    ...mapGetters("contratos", {
      loading: "loading",
      contratos: "contratos",
    }),
    ...mapGetters("geral", {
      resultadoPesquisa: "resultadoPesquisa",
    }),
    ...mapGetters("cliente", {
      consultaClientes: "consultaClientes",
    }),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("auth", {
      usuarioEmpresas: "usuarioEmpresas",
    }),

    resultadosPorEmpresa() {
      const resultadoCliente = this.consultaClientes;
      const resultadoContratos = this.contratos;

      const resultadoAgrupado = this.usuarioEmpresas.map((e) => {
        const resultObj = new Object();
        resultObj["empresa"] = e.chave;
        resultObj["empresaNome"] = e.nome;
        resultObj["consultaCliente"] = [];
        resultObj["consultaContrato"] = [];
        return resultObj;
      });

      if (resultadoCliente && resultadoCliente.length) {
        resultadoCliente.forEach((cliente) => {
          const agrupadoItem = resultadoAgrupado.find((ra) => ra.empresa === cliente.empresa);
          if (agrupadoItem) {
            agrupadoItem["consultaCliente"] = [...cliente.data];
          }
        });
      }

      if (resultadoContratos && resultadoContratos.length) {
        resultadoContratos.forEach((contrato) => {
          const agrupadoItem = resultadoAgrupado.find((ra) => ra.empresa === contrato.empresa);
          if (agrupadoItem) {
            agrupadoItem["consultaContrato"] = [...contrato.data];
          }
        });
      }

      return resultadoAgrupado;
    },

    logsPorEmpresa() {
      const logs = this.ultimosContratosAcessados;

      const resultadoAgrupado = this.usuarioEmpresas.map((e) => {
        const resultObj = new Object();
        resultObj["empresa"] = e.chave;
        resultObj["empresaNome"] = e.nome;
        resultObj["logs"] = [];
        return resultObj;
      });

      if (logs && logs.length) {
        logs.forEach((log) => {
          const agrupadoItem = resultadoAgrupado.find((ra) => ra.empresa === log.empresa);
          if (agrupadoItem) {
            agrupadoItem["logs"] = [...log.data];
          }
        });
      }

      return resultadoAgrupado;
    },
  },
};
</script>

<style lang="scss" scoped>
.formulario {
  max-width: 800px;
}
</style>

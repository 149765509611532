<template>
  <v-dialog :width="retornaLargura()" persistent scrollable v-model="dialog">
    <v-card style="min-height: 60vh" class="elevation-0 rounded-0">
      <!-- Cabeçalho -->
      <v-card-title class="text-h5">
        <span class="mr-2">{{ campanha?.id ? "Editar campanha" : "Nova campanha" }}</span>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!camposCampanha.tipo_regua"
          text
          color="primary"
          @click="abrirVariaveis ? (abrirVariaveis = false) : (abrirVariaveis = true)"
        >
          Variáveis
        </v-btn>
      </v-card-title>
      <!-- Fomulário -->
      <v-card-text class="pt-4 pb-4">
        <v-form class="d-flex fill-height" ref="form" v-model="isFormValid">
          <v-row>
            <v-col :cols="12" class="campos campos-form pt-6 pb-0">
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-select
                    dense
                    v-model="camposCampanha.tipo_regua"
                    outlined
                    @change="trocarVariaveis"
                    :rules="[() => !!camposCampanha.tipo_regua || 'Campo obrigatório']"
                    :loading="carregandoVariaveis"
                    label="Tipo da régua"
                    :items="tiposRegua()"
                    item-value="tipo"
                    item-text="tipo"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.tipo | tipoRegua }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.tipo | tipoRegua }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    dense
                    outlined
                    label="Nome da campanha"
                    color="deep-purple accent-2"
                    autofocus
                    @focus="mudaCampoSelecionado(null)"
                    v-model="camposCampanha.campanha"
                    :rules="regras.exigido"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="py-0">
                  <v-textarea
                    dense
                    outlined
                    persistent-hint
                    label="Roteiro"
                    color="deep-purple accent-2"
                    hint="Não pode conter emojis ou caracteres especiais"
                    v-model="camposCampanha.roteiro"
                    ref="roteiro"
                    rows="3"
                    :rules="regras.roteiroCampanha"
                  ></v-textarea>

                  <span
                    style="margin-top: -25px"
                    class="d-flex justify-end"
                    :class="
                      Number(camposCampanha?.roteiro?.length || 0) > maxLength ? 'counter-sms' : ''
                    "
                  >
                    {{ Number(camposCampanha?.roteiro?.length || 0) }} / {{ maxLength }}
                  </span>
                </v-col>

                <v-col cols="12" class="py-0 pt-2">
                  <v-checkbox
                    v-model="camposCampanha.fast_sms"
                    label="Habilitar Fast SMS"
                    class="text-sm mt-0"
                  ></v-checkbox>
                </v-col>

                <v-col v-if="camposCampanha.fast_sms" cols="12" class="py-0">
                  <v-textarea
                    dense
                    outlined
                    persistent-hint
                    label="Mensagem Fast SMS"
                    color="deep-purple accent-2"
                    hint="Não pode conter emojis ou caracteres especiais"
                    v-model="camposCampanha.mensagem_fast_sms"
                    ref="mensagem_fast_sms"
                    @focus="mudaCampoSelecionado('mensagem_fast_sms')"
                    rows="2"
                    :rules="regras.campoSms"
                  ></v-textarea>
                  <span
                    style="margin-top: -25px"
                    class="d-flex justify-end"
                    :class="conteudoFastSMS > maxLengthSMS ? 'counter-sms' : ''"
                  >
                    {{ conteudoFastSMS }} / {{ maxLengthSMS }}
                  </span>

                  <div
                    v-if="preVisualizarMensagem(camposCampanha.mensagem_fast_sms)"
                    v-html="preVisualizarMensagem(camposCampanha.mensagem_fast_sms)"
                    class="ml-pre-visualizacao mt-4"
                  ></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- Ações -->

      <v-card-actions class="flex-column align-end">
        <div>
          <v-btn color="primary" text @click="limparDialog()"> Cancelar </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :disabled="!isFormValid || salvando || !isFormValid"
            :loading="salvando"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <div v-show="abrirVariaveis" class="listaVariaveis">
      <ListaVariaveis
        :abrirVariaveis="abrirVariaveis"
        @concatenaVariavel="concatenaVariavel"
        @fecharVariaveis="abrirVariaveis = false"
      />
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import variavelMensagemService from "@/services/variavel-mensagem";
import rules from "@/assets/js/rules";
import ListaVariaveis from "../../reguas/components/ListaVariaveis.vue";

export default {
  name: "DialogCampanha",
  components: {
    ListaVariaveis,
  },
  data: () => ({
    dialog: false,
    campanha: {},
    salvando: false,
    isFormValid: false,
    campoSelecionado: null,
    regras: rules,
    camposAceitamVariavel: ["mensagem_fast_sms"],
    abrirVariaveis: false,
    maxLength: 256,
    maxLengthSMS: 160,
    textSMSVariable: 0,
    textoFastSMS: "",
    carregandoVariaveis: false,
    camposCampanha: {
      campanha: null,
      tipo_regua: null,
      roteiro: null,
      fast_sms: false,
      mensagem_fast_sms: null,
    },
  }),
  computed: {
    ...mapState("regua", ["regua"]),
    ...mapGetters("variavelRegua", ["variaveis"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),
    ...mapGetters("reguas", ["tiposRegua"]),

    conteudoFastSMS() {
      let cont = 0;
      this.textSMSVariable = 0;
      this.textoFastSMS = this.camposCampanha.mensagem_fast_sms;
      if (this.textoFastSMS == null) return;
      this.variaveis.forEach((variable) => {
        while (this.textoFastSMS.includes(`{{${variable.nome}}}`) && variable.tamanho) {
          this.textoFastSMS = this.textoFastSMS.replace(`{{${variable.nome}}}`, "");
          cont += variable.tamanho;
          this.textSMSVariable = cont;
        }
      });
      this.textSMSVariable += this.textoFastSMS.length;
      return this.textSMSVariable;
    },
  },

  watch: {
    dialog() {
      if(this.dialog) {
        this.limpaCampos()
        this.inicializarDialog();
        this.trocarVariaveis()
      }
    },
    "camposCampanha.fast_sms": function (newVal, oldVal) {
      if (!newVal) {
        this.camposCampanha.mensagem_fast_sms = null;
      }
    },
  },
  methods: {
    ...mapActions("variavelRegua", { pegarVariaveis: "pegar" }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapActions("campanhas", {
      salvarCampanha: "salvarCampanha",
    }),

    /** Retorna a mensagem com as variáveis substituídas pelos exemplos. */
    preVisualizarMensagem: variavelMensagemService.preVisualizarMensagem,

    // DIALOG

    retornaLargura() {
      if (!this.abrirVariaveis) {
        return "650";
      } else {
        return "1050";
      }
    },

    inicializarDialog() {
      this.camposCampanha = {
        campanha: this.campanha?.campanha,
        tipo_regua: this.campanha?.tipo_regua,
        roteiro: this.campanha?.roteiro,
        fast_sms: this.campanha?.metadados?.fast_sms || false,
        mensagem_fast_sms: this.campanha?.metadados?.mensagem_fast_sms,
      };
    },

    abrirDialog() {
      this.dialog = true;
    },
    
    fecharDialog() {
      this.dialog = false
    },

    limparDialog() {
      this.limpaCampos();
      this.$emit("cancelar", false);
    },

    async trocarVariaveis() {
      this.abrirVariaveis = false;
      this.carregandoVariaveis = true;
      await this.pegarVariaveis({
        tipo_regua: this.camposCampanha.tipo_regua,
        empresa_id: this.empresaSelecionada.id,
      });
      this.carregandoVariaveis = false;
    },

    // MISC

    async save(evento) {
      console.debug("Salvando a campanha");
      evento.preventDefault();

      if (Number(this.camposCampanha?.roteiro?.length || 0) > this.maxLength) {
        this.showSnackBar({
          text: "O texto do roteiro deve ter no máximo 256 caracteres!",
        });
        return;
      }
      if (
        this.camposCampanha.fast_sms &&
        Number(this.camposCampanha?.fast_sms?.length || 0) > this.maxLengthSMS
      ) {
        this.showSnackBar({
          text: "O texto do Fast SMS deve ter no máximo 160 caracteres!",
        });
        return;
      }

      if (!this.$refs.form.validate()) {
        return;
      }

      this.salvando = true;

      const payload = {
        id: this.campanha?.id,
        campanha: this.camposCampanha?.campanha,
        tipo_regua: this.camposCampanha?.tipo_regua,
        roteiro: this.camposCampanha?.roteiro,
        metadados: {
          fast_sms: this.camposCampanha?.fast_sms || false,
          mensagem_fast_sms: this.camposCampanha?.mensagem_fast_sms,
        },
      };

      try {
        await this.salvarCampanha(payload);
        this.limparDialog();
        this.showSnackBar({
          text: "Campanha " + (this.campanha?.id ? "editada" : "criada") + " com sucesso!",
        });
      } catch (err) {
        console.error("Não foi possível salvar a campanha", err);
        this.showSnackBar({
          text: "Erro ao " + (this.campanha?.id ? "editar" : "criar") + " a campanha!",
        });
      } finally {
        this.salvando = false;
      }
    },

    prepareData(item) {
      return typeof item === "undefined" || typeof item === null ? null : item;
    },

    mudaCampoSelecionado(campo) {
      this.campoSelecionado = campo;
    },

    concatenaVariavel(variavel) {
      if (!this.campoSelecionado) {
        this.showSnackBar({
          text: "Campo não suporta variáveis!",
        });
      }

      const posicaoCursor = this.$refs[this.campoSelecionado]?.$refs.input.selectionStart;
      const stringParaArray = (this.camposCampanha[this.campoSelecionado] || "").split("");
      stringParaArray.splice(posicaoCursor, 0, `{{${variavel.nome}}}`);
      this.camposCampanha[this.campoSelecionado] = stringParaArray.join("");
      this.$refs[this.campoSelecionado]?.$refs.input.focus();
    },

    limpaCampos() {
      for (const key of Object.keys(this.camposCampanha)) {
        this.camposCampanha[key] = null;
      }

      this.mudaCampoSelecionado(null);
      this.abrirVariaveis = false;
      this.$refs.form && this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped lang="scss">
.ml-pre-visualizacao-multiplo {
  word-break: break-word;
  background-color: #e3f2fd;
  border-radius: 4px;
  font-size: 13px;
  padding: 8px 8px 4px;
  position: relative;
}

.ml-pre-visualizacao-multiplo::before {
  background-color: #e3f2fd;
  border-radius: 4px;
  content: "Pré-visualização para múltiplos";
  font-size: 10px;
  font-weight: 500;
  padding: 0 8px;
  position: absolute;
  left: 0px;
  top: -10px;
}

::-webkit-scrollbar {
  width: 4px;
  margin-left: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}

.text-black {
  color: black;
}

.counter-sms {
  color: red;
}

.select-nps,
.select-pesquisa {
  max-width: 625px;
}

.campos-form {
  scroll-behavior: smooth;
}

.listaVariaveis {
  width: 68.5%;
  & > div {
    height: 100%;
    width: 100% !important;
  }
}

.preview {
  max-width: 500px;
}
</style>

<template>
  <div>
    <v-app-bar app>
      <span class="text-h6 font-weight-bold ml-3">{{ $route.meta.title }} </span>
      <v-spacer></v-spacer>
      <v-text-field
        clearable
        filled
        dense
        rounded
        :solo="true"
        class="grey lighten-4 search-field mr-4"
        placeholder="Nome do perfil"
        hide-details=""
        v-model="filtros.nome_longo"
        @click:clear="handleClear"
      >
        <template v-slot:prepend-inner>
          <div class="d-flex align-center justify-center">
            <v-icon>mdi-magnify</v-icon>
          </div>
        </template>
      </v-text-field>
    </v-app-bar>

    <v-container
      v-click-outside="() => (selectedPerfil = -1)"
      class="flex flex-col gap-5 w-10/12 perfisEmpresa"
    >
      <div v-if="loadingPerfis" class="flex flex-col items-center justify-center mt-12">
        <v-progress-circular width="6" indeterminate color="primary" size="100">
        </v-progress-circular>
        <div class="mt-4">
          <span class="text-xl tex-grey weight">Carregando perfis...</span>
        </div>
      </div>

      <div
        v-else
        v-for="(perfil, indexPerfil) in perfis"
        :key="indexPerfil"
        class="elevation-1 rounded-md w-full bg-white perfilEmpresa"
        :class="`perfilEmpresa-${indexPerfil}`"
      >
        <div class="bg-gray-300 -mb-4 z-50 rounded-t-md relative text-center" v-if="perfil.novo">
          <span class="text-sm text-gray-600">Rascunho</span>
        </div>
        <div
          class="bg-gray-300 -mb-4 z-50 rounded-t-md relative text-center"
          v-if="verificaSeAlterouPerfil(perfil) && !perfil.novo"
        >
          <span class="text-sm text-gray-600">Alterações pendentes</span>
        </div>
        <v-slide-y-transition hide-on-leave v-if="selectedPerfil == indexPerfil">
          <v-form
            :ref="`form-${indexPerfil}`"
            :class="{ 'mt-6': perfil.novo || verificaSeAlterouPerfil(perfil) }"
          >
            <div v-for="(field, index) in perfil" :key="index">
              <div class="grid grid-cols-2 items-center py-2 px-4" v-if="field.component">
                <div class="flex flex-col self-start">
                  <span class="text-lg text-gray-800">
                    {{ field.translation }}
                  </span>
                  <span class="text-xs text-gray-500">
                    {{ field.description }}
                  </span>
                </div>
                <component
                  class="self-center"
                  v-bind="field.attrs"
                  v-model="field.value"
                  :is="field.component"
                  dense
                ></component>
              </div>
              <v-divider v-if="field.component"></v-divider>
            </div>
            <div class="py-4 px-2 flex justify-end w-full gap-4">
              <v-btn
                color="primary"
                @click="reverterAlteracoes(perfil)"
                v-if="verificaSeAlterouPerfil(perfil) && perfilJaCadastrado(perfil) && !perfil.novo"
                :disabled="loadingSalvar"
                text
                >Reverter alterações</v-btn
              >
              <v-btn
                color="primary"
                @click.stop="salvarPerfil(perfil, indexPerfil)"
                :disabled="!verificaSeAlterouPerfil(perfil) || loadingSalvar"
                :loading="loadingSalvar"
                >{{ perfil.novo ? "criar" : "salvar" }}</v-btn
              >
              <v-btn
                text
                color="primary"
                @click.stop="selectedPerfil = -1"
                :disabled="loadingSalvar"
                >fechar</v-btn
              >
            </div>
          </v-form>
        </v-slide-y-transition>

        <v-slide-y-transition hide-on-leave v-else>
          <div
            @click.stop="selectedPerfil = indexPerfil"
            :style="{
              'border-left': '8px solid' + (perfil.tema.value.primary || 'red'),
            }"
            class="py-4 px-8 w-full flex rounded-l-lg cursor-pointer"
            :class="{ 'pt-6': perfil.novo || verificaSeAlterouPerfil(perfil) }"
          >
            <img class="w-24 h-20 object-contain" :src="perfil.url_logo.value" alt="" />
            <div class="flex flex-col ml-4">
              <div class="flex align-center gap-2">
                <span class="text-2xl">
                  {{ perfil.nome_longo.value ?? "Nome longo" }}
                </span>
                <v-tooltip contained right>
                  <template v-slot:activator="{ on }">
                    <v-chip
                      :color="`${perfil.genero.value === 'M' ? 'blue' : 'red'}`"
                      outlined
                      v-on="on"
                      small
                      class="text-sm text-gray-400"
                    >
                      {{ perfil.genero.value === "M" ? "Masculino" : "Feminino" ?? "Genero" }}
                    </v-chip>
                  </template>
                  <span>Define a concordância nominal</span>
                </v-tooltip>
              </div>
              <span class="text-lg text-gray-400 leading-6">{{
                perfil.nome_curto.value ?? "Nome curto"
              }}</span>
              <span class="text-sm italic text-gray-400 leading-6">{{
                perfil.chave.value ?? "chave"
              }}</span>
            </div>
            <div
              v-show="
                perfil.tema.value.primary && perfil.tema.value.secondary && perfil.tema.value.accent
              "
            >
              <div class="h-full flex flex-column justify-center gap-2">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <div
                      v-on="on"
                      :style="{ background: perfil.tema.value.primary }"
                      class="h-4 w-4 mx-1 rounded-lg"
                    ></div>
                  </template>
                  <span>Cor primária</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <div
                      v-on="on"
                      :style="{ background: perfil.tema.value.secondary }"
                      class="h-4 w-4 mx-1 rounded-lg"
                    ></div>
                  </template>
                  <span>Cor secundária</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <div
                      v-on="on"
                      :style="{ background: perfil.tema.value.accent }"
                      class="h-4 w-4 mx-1 rounded-lg"
                    ></div>
                  </template>
                  <span>Cor de destaque</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-slide-y-transition>
      </div>

      <FloatingBtn :disabled="perfis.some((perfil) => perfil.novo)" @click="adicionaNovoPerfil" />
    </v-container>
  </div>
</template>

<script>
import { VSelect, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import EditorTema from "./components/EditorTema.vue";
import FileInput from "./components/FileInput.vue";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    FloatingBtn,
    FileInput,
    EditorTema,
    VSelect,
    VTextField,
  },
  data: () => ({
    loadingPerfis: false,
    perfis: [],
    perfisSource: [],
    selectedPerfil: null,
    loadingSalvar: false,
    filtros: {
      nome_longo: null,
    },
    fields: {
      chave: {
        value: null,
        description:
          "	Chave do perfil para ser associado aos contratos. Qualquer alteração deste campo deve ser alinhada com a equipe de T.I. para evitar divergências.",
        translation: "Chave do perfil",
        component: "v-text-field",
        attrs: {
          outlined: true,
          rules: [(v) => !!v || "Campo obrigatório"],
          counter: 128,
          maxLength: 128,
        },
      },
      genero: {
        value: "M",
        description:
          "Indica qual gênero devemos considerar para realizar a concordância nominal nas comunicações.",
        translation: "Gênero",
        component: "v-select",
        attrs: {
          outlined: true,
          attach: true,
          items: [
            {
              text: "Masculino",
              value: "M",
            },
            {
              text: "Feminino",
              value: "F",
            },
          ],
        },
      },
      nome_longo: {
        value: null,
        translation: "Nome longo",
        description: "Nome longo da empresa para ser utilizado nas comunicações.",
        component: "v-text-field",
        attrs: {
          outlined: true,
          rules: [(v) => !!v || "Campo obrigatório"],
          counter: 128,
          maxLength: 128,
        },
      },
      nome_curto: {
        value: null,
        translation: "Nome curto",
        description: "Nome curto da empresa para ser utilizado nas comunicações.",
        component: "v-text-field",
        attrs: {
          outlined: true,
          rules: [(v) => !!v || "Campo obrigatório"],
          counter: 128,
          maxLength: 128,
        },
      },
      tema: {
        value: {},
        translation: "Tema",
        description: "Definem as cores a serem utilizadas nas telas do Autosserviço.",
        component: "editor-tema",
        attrs: {
          outlined: true,
          rules: [(v) => !!v || "Campo obrigatório"],
        },
      },
      url_logo: {
        value: null,
        translation: "Logotipo do perfil",
        component: "file-input",
        attrs: {
          outlined: true,
          rules: [(v) => !!v || "Campo obrigatório"],
        },
      },
    },
  }),
  async created() {
    this.loadingPerfis = true;
    await this.buscaperfisEmpresa({ ordem: "-criado_em", limite: 9999 });
    this.loadingPerfis = false;

    this.populaCampos();
  },
  mounted() {},
  methods: {
    ...mapActions("perfisEmpresa", [
      "buscaperfisEmpresa",
      "criaPerfilEmpresa",
      "alteraPerfilEmpresa",
    ]),
    ...mapActions("snackbar", ["showSnackBar"]),

    handleClear() {
      this.filtros.nome_longo = "";
    },

    async populaCampos() {
      try {
        this.perfis = [];
        this.perfisSource = [];
        structuredClone(this.perfisEmpresa).forEach((perfil) => {
          const item = Object.entries(this.fields).map(([key, value]) => {
            if (key == "chave") {
              value.attrs.disabled = true;
            }
            return [
              key,
              {
                ...value,
                value: perfil[key],
              },
            ];
          });
          this.perfisSource.push(Object.fromEntries(item));
        });

        this.perfis = this.perfisSource.sort((a, b) => {
          if (a.nome_longo.value > b.nome_longo.value) return 1;
          if (a.nome_longo.value < b.nome_longo.value) return -1;
          else return 0;
        });

        this.loadingPerfis = false;
      } catch (e) {
        console.error(e);
      }
    },
    perfilJaCadastrado(perfil) {
      const perfilJaCadastrado = this.perfisEmpresa.find(
        (item) => item.chave == perfil.chave.value
      );
      if (perfilJaCadastrado) return perfilJaCadastrado;
      return false;
    },
    verificaSeAlterouPerfil(perfil) {
      if (perfil.novo) return true;
      const perfilJaCadastrado = this.perfisEmpresa.find(
        (item) => item.chave == perfil.chave.value
      );
      if (!perfilJaCadastrado) return false;
      const valorAlterado = Object.entries(perfil).some(([key, value]) => {
        return JSON.stringify(perfilJaCadastrado[key]) !== JSON.stringify(value.value);
      });
      return valorAlterado;
    },
    reverterAlteracoes(perfil) {
      const perfilJaCadastrado = this.perfilJaCadastrado(perfil);
      try {
        Object.entries(perfilJaCadastrado).forEach(([chave, valor]) => {
          if (perfil[chave]) perfil[chave].value = valor;
        });
        this.showSnackBar({
          text: "Alterações revertidas com sucesso!",
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: "Falha ao reverter alterações, recarregue a pagina.",
          type: "error",
        });
      }
    },
    async salvarPerfil(perfil, indexPerfil) {
      const form = this.$refs[`form-${indexPerfil}`];
      const valid = await form[0].validate();
      if (!valid) return;
      const payload = Object.entries(perfil).reduce((resultObject, [key, valor]) => {
        Object.assign(resultObject, { [key]: valor.value });
        return resultObject;
      }, {});
      try {
        this.loadingSalvar = true;
        if (perfil.novo) {
          await this.criaPerfilEmpresa(payload);
          this.perfis.splice(indexPerfil, 1);
          this.populaCampos();
          this.selectedPerfil = null;
        } else {
          await this.alteraPerfilEmpresa(payload);
          this.selectedPerfil = null;
          this.$nextTick(() => {
            const perfil = document.querySelector(`.perfilEmpresa-${indexPerfil}`);
            this.$vuetify.goTo(perfil, {
              container: ".v-main__wrap",
              offset: 0,
              easing: "easeInOutCubic",
            });
          });
        }
        this.showSnackBar({
          text: "Perfil salvo com sucesso",
          type: "success",
        });
      } catch (e) {
        this.showSnackBar({ text: "Falhas ao salvar o perfil", type: "error" });
      } finally {
        this.loadingSalvar = false;
      }
    },
    async adicionaNovoPerfil(event) {
      event.stopPropagation();

      const novoPerfil = cloneDeep(this.fields);
      novoPerfil.chave.attrs.disabled = false;
      novoPerfil.chave.attrs.rules.push(this.validaChaveUnica);
      novoPerfil.novo = true;
      this.perfis.unshift(novoPerfil);
      this.selectedPerfil = 0;
      this.$nextTick(() => {
        const primeiroPerfil = document.querySelector(".perfilEmpresa");
        this.$vuetify.goTo(primeiroPerfil, {
          container: ".v-main__wrap",
          offset: 0,
          easing: "easeInOutCubic",
        });
      });
    },
    validaChaveUnica(value) {
      const chavejaExiste = this.perfisEmpresa.some((perfil) => perfil.chave == value);
      return !chavejaExiste || "Chave em uso por outro perfil.";
    },
  },
  computed: {
    ...mapGetters("perfisEmpresa", ["perfisEmpresa"]),
  },
  watch: {
    selectedPerfil(newValue) {
      if (newValue == null || newValue < 0) return;
      this.$nextTick(() => {
        const perfilSelecionado = document.querySelector(`.perfilEmpresa-${newValue}`);
        this.$vuetify.goTo(perfilSelecionado, {
          container: ".v-main__wrap",
          offset: -40,
          easing: "easeInOutCubic",
        });
      });
    },
    filtros: {
      handler(value) {
        if (value) {
          this.perfis = this.perfisSource.filter((perfil) => {
            return perfil.nome_longo.value.toLowerCase().includes(value.nome_longo.toLowerCase());
          });
        } else {
          this.perfis = this.perfisSource;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.v-input__prepend-inner {
  margin: 0px !important;
  align-items: center;
  height: 100%;
  align-self: center !important;
}

.search-field {
  max-width: 400px;
}
</style>
